<template>
    <div class="cont">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item to="/companies_manage">租户/商户管理</el-breadcrumb-item>
             <el-breadcrumb-item>{{ reversedContent }}</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <!-- 返回页头 -->
       <el-page-header @back="goBack" :content="reversedContent"></el-page-header>
       <el-row class="cont-form-box">
          <el-form class="form-box" :model="ruleForm" :rules="rules" ref="ruleForm">
             <el-form-item label="用户名" prop="userName">
                <el-input :disabled="action === 'edit'" v-model.trim="ruleForm.userName" :placeholder="$t('msg.user_name')" size="medium" clearable></el-input>
             </el-form-item>
             <el-form-item label="手机号" prop="mobile">
                <el-input :disabled="action === 'edit'" v-model.trim="ruleForm.mobile" size="medium" :placeholder="$t('msg.phone_number')"></el-input>
             </el-form-item>
             <el-form-item label="单位邮箱" prop="email">
                <el-input :disabled="action === 'edit'" v-model.trim="ruleForm.email" :placeholder="$t('msg.company_email')" size="medium" clearable></el-input>
             </el-form-item>
             <el-form-item label="单位名称" prop="companyName">
                <el-input :disabled="action === 'edit'" v-model.trim="ruleForm.companyName" :placeholder="$t('msg.company_name')" size="medium" clearable></el-input>
             </el-form-item>
             <el-form-item label="法人代表" prop="legalPerson">
                <el-input v-model="ruleForm.legalPerson" :placeholder="$t('msg.legal_person')" maxLength='11' size="medium" clearable></el-input>
             </el-form-item>
             <el-form-item label="单位性质" prop="companyNature">
                <el-select v-model="ruleForm.companyNature" :placehoder="$t('msg.select')" size="medium">
                   <el-option label="企业" value="1"></el-option>
                   <el-option label="政府机关" value="2"></el-option>
                   <el-option label="社会团体" value="3"></el-option>
                </el-select>
             </el-form-item>
             <el-form-item label="商家分类" prop="bizType" v-if="reversedDisabled">
                <el-radio-group v-model="ruleForm.bizType" size="medium">
                   <el-radio label="BUSINESS">酒店商户</el-radio>
                   <el-radio label="SHOPPING">商城商家</el-radio>
                </el-radio-group>
             </el-form-item>
             <el-form-item label="LOGO">
                <el-upload
                      class="avatar-uploader"
                      ref="upload"
                      :show-file-list="false"
                      action="#"
                      :auto-upload="false"
                      :on-change="handleUpload">
                   <img v-if="companyLogo" :src="companyLogo" class="avatar">
                   <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
             </el-form-item>
             <el-form-item>
                <el-button size="medium" class="m-right-30" type="primary" plain @click="handleReset()" v-text="$t('msg.reset')">重置</el-button>
                <el-button size="medium" class="bg-gradient" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
             </el-form-item>
          </el-form>
       </el-row>
    </div>
</template>

<script>
import { system } from '@/api/interface/system'
import { beforeUpload, verificationRule } from '@/common/js/common'
export default {
   data(){
      let validMobile = (rule, value, callback) => {
         if (!value) {
            callback(new Error('请输入手机号'))
         }else if (verificationRule.mobile(value)) {
            callback(new Error('手机号格式错误'))
         }else {
            callback()
         }
      }
      let validEmail = (rule, value, callback) => {
         if (!value) {
            callback(new Error('请输入邮箱'))
         }else if (verificationRule.email(value)) {
            callback(new Error('邮箱格式错误'))
         }else {
            callback()
         }
      }
      return{
         action: '',           // 当前操作行为
         active: 0,            // 进度条状态
         companyLogo: '',      // LOGO路径
         tenantId: '',         // 所属商户的租户ID
         id: '',               // 公司ID
         fileForm: {
            file: null,
            module: 'system',
            menu: 'company',
            func: 'logo',
            category: 'logo',
            isThumb: 'true'
         },
         ruleForm: {
            userName: '',         // 用户名
            mobile: '',           // 手机号
            email: '',            // 邮箱
            companyName: '',      // 名称
            legalPerson: '',      // 法人
            companyNature: '1',   // 性质
            bizType: 'BUSINESS',  // 商家分类
            type: '',             // 添加类型 STORE TENANT
         },
         rules: {
            userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
            mobile: [{ required: true, validator: validMobile, trigger: 'blur' }],
            email: [{ required: true, validator: validEmail, trigger: 'blur' }],
            companyName: [{ required: true, message: '请输入单位名称', trigger: 'blur' }]
         },
         add_success: "添加成功！",
         update_success: "修改成功！",
      }
   },
   mounted() {
      this.action = this.$route.query.act
      this.ruleForm.type = this.$route.query.type
      this.tenantId = this.$route.query.tenantId
      this.action === 'edit' && this.echoCompany()
   },
   computed: {
      reversedContent(){
         if (this.action === 'add') {
            if (this.ruleForm.type === 'TENANT') {
               return '新增租户'
            }else {
               return '新增商户'
            }
         }else {
            if (this.ruleForm.type === 'TENANT') {
               return '编辑租户'
            }else {
               return '编辑商户'
            }
         }
      },
      reversedDisabled() {
         if (this.action === 'edit') {
            return false
         }else {
            if (this.ruleForm.type === 'TENANT') {
               return false
            } else {
               return true
            }
         }
      }
   },
   beforeDestroy() {
      sessionStorage.removeItem('companyInfo')
   },
   methods: {
      // 编辑时回显数据
      echoCompany(){
         const companyInfo = JSON.parse(sessionStorage.getItem('companyInfo'))
         for (let k in companyInfo) k in this.ruleForm && (this.ruleForm[k] = companyInfo[k])
         this.id = companyInfo.id
         companyInfo.companyLogo && (this.companyLogo = companyInfo.companyLogo)
      },
      // 获取上传的文件资源
      handleUpload(file){
         let error = beforeUpload(file)
         if (error) return this.$message.error(error)
         this.companyLogo = URL.createObjectURL(file.raw);
         this.fileForm.file = file.raw
      },
      // 重置表单
      handleReset(){
         if (this.action === 'add'){
            this.ruleForm.userName = ''
            this.ruleForm.mobile = ''
            this.ruleForm.email = ''
            this.ruleForm.companyName = ''
         }
         this.ruleForm.legalPerson = ''
         this.ruleForm.companyNature = '1'
      },
      // 保存操作
      handleSave(){
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            let url = system.addCompany
            let param = new FormData()
            if (this.fileForm.file) for (let k in this.fileForm) param.set(k, this.fileForm[k])
            for (let key in this.ruleForm) key !== 'bizType' && param.append(key, this.ruleForm[key])
            if (this.ruleForm.type === 'STORE'){
               param.append('tenantId', this.tenantId)
               param.append('bizType', this.ruleForm.bizType)
               if (this.ruleForm.bizType === 'SHOPPING'){
                  param.set('type', 'BUSINESS_SHOPPING')
               }else if(this.ruleForm.bizType === 'SERVICES'){
                  param.set('type', 'BUSINESS_SERVICES')
               }else {
                  param.set('type', 'STORE')
               }
            }
            if (this.action === 'edit'){
               url = system.editCompany
               param.append('id', this.id)
               param.delete('tenantId')
               for (let k in param) {
                  if (k === 'legalPerson' || k === 'companyNature') continue
                  param.delete(k)
               }
            }
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : '编辑成功！',
                     type: 'success'
                  })
                  this.goBack()
               }
            })
         })
      },
      // 返回上页
      goBack(){
         this.$router.go(-1)
      }
   }
}
</script>

<style scoped lang="scss"></style>
